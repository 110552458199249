import React from 'react';
import type { WCmsWeddingPartyPageView, WPublicThemeV2View } from '@zola/svc-web-api-ts-client';

import getNonHomeCmsHeroImg from '~/components/publicWebsiteV2/util/getNonHomeCmsHeroImg';

import TitleImgDesc from '~/components/publicWebsiteV2/common/TitleImgDesc';

import { getWeddingPartyMemberRole } from '~/components/manage/website/entities/WeddingPartyMemberRoles';

import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';

import { PageAnimationWrapper } from '~/components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';
import { FoilAnimationWrapper } from '~/components/publicWebsiteV2/common/FoilAnimation/FoilAnimationWrapper/FoilAnimationWrapper';
import { FoilAnimationProvider } from '~/components/publicWebsiteV2/common/FoilAnimation/FoilAnimationContext/FoilAnimationProvider';
import { useAnimationContext } from '~/components/manage/EditWebsite/EditWebsiteAddEffects/components/AnimationContext/AnimationContext';
import {
  Container,
  Person,
  Headshot,
  Name,
  Role,
  Blurb,
  EmptyStateMessage,
  StyledHr,
  PersonWrapper,
} from './WeddingParty.styles';

type WeddingPartyProps = {
  pageData?: WCmsWeddingPartyPageView;
};

/**
 * Used for single and multi page layouts
 */
const WeddingParty = ({ pageData }: WeddingPartyProps): JSX.Element => {
  const { title, description, wedding_party_members, header_image_url, images } = pageData || {};
  const {
    state: {
      components: {
        styleCmsEntityBodyFont,
        styleCmsEntityHeaderFont,
        styleCmsEntityBodyFont2,
        cmsEntityComponentBodyFontValues,
      },
      inPreview,
      wedding,
    },
  } = useWebsiteThemeContext();
  const { showAnimationsInEditWebsite, isMobileDevice } = useAnimationContext();

  const isSinglePageLayout =
    wedding?.public_theme_v2?.layout_type ===
    (('SINGLE_PAGE' as unknown) as WPublicThemeV2View.LayoutTypeEnum);
  const animationIndexModifier = isSinglePageLayout || isMobileDevice ? 1 : 0;

  const orientation =
    wedding?.public_theme_v2?.components?.CMS_WEDDING_PARTY?.option_type_values?.ORIENTATION
      ?.value || 'HORIZONTAL';

  const showEmptyStateMessage = wedding_party_members?.length === 0 && !description;

  const cmsHeaderImgUrl = getNonHomeCmsHeroImg(images);

  const StyledName = styleCmsEntityHeaderFont(Name);
  const StyledBlurb = styleCmsEntityBodyFont(Blurb);
  const StyledEmptyStateMessage = styleCmsEntityBodyFont(EmptyStateMessage);
  const StyledRole = styleCmsEntityBodyFont2(Role);

  return (
    <>
      <TitleImgDesc
        title={title}
        url={cmsHeaderImgUrl || header_image_url}
        description={description}
      />
      {wedding_party_members && wedding_party_members.length > 0 && (
        <Container orientation={orientation}>
          {wedding_party_members?.map((person, i) => {
            const direction = i % 2 ? 'left' : 'right';
            const indexToUse = i + animationIndexModifier;
            return (
              <FoilAnimationProvider
                key={person.id}
                style={{
                  borderRightColor: `#${cmsEntityComponentBodyFontValues.color}`,
                }}
              >
                <PersonWrapper showAnimations={showAnimationsInEditWebsite}>
                  <PageAnimationWrapper
                    index={indexToUse}
                    animationProps={{
                      direction: {
                        wipeDirection: direction,
                        panDirection: direction,
                      },
                    }}
                  >
                    <Person
                      style={{ borderRightColor: `#${cmsEntityComponentBodyFontValues.color}` }}
                    >
                      {person.image_url && <Headshot src={person.image_url} />}
                      <StyledName>
                        <FoilAnimationWrapper text={person.name} />
                      </StyledName>
                      <StyledRole>
                        {person.role === 'CUSTOM'
                          ? person.custom_role
                          : getWeddingPartyMemberRole(person.role)}
                      </StyledRole>
                      <StyledBlurb>{person.description}</StyledBlurb>
                    </Person>
                  </PageAnimationWrapper>
                  {orientation === 'HORIZONTAL' &&
                  i !== wedding_party_members.length - 1 &&
                  (i + 1) % 2 &&
                  inPreview !== 'MOBILE' ? (
                    <PageAnimationWrapper
                      showOnPageLoad={i === 0}
                      animationNameOverride="page-animation-fade"
                      index={i + 1}
                      style={{ borderRightColor: `#${cmsEntityComponentBodyFontValues.color}` }}
                    >
                      <StyledHr />
                    </PageAnimationWrapper>
                  ) : null}
                </PersonWrapper>
              </FoilAnimationProvider>
            );
          })}
        </Container>
      )}
      {showEmptyStateMessage && (
        <PageAnimationWrapper>
          <StyledEmptyStateMessage>Wedding party coming soon!</StyledEmptyStateMessage>
        </PageAnimationWrapper>
      )}
    </>
  );
};

export default WeddingParty;
